import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import tw, { styled } from 'twin.macro'

import {Layout} from '@components/layout/layout'
import { H1, MainContent } from "@components/layout/style"


const ContactContent= styled('div')`
  ${tw`md:mx-0 mx-4`}
    margin-bottom: 50px;
`

const HeroImg= styled('div')`
  ${tw`lg:block md:hidden`}
    margin-bottom: 50px;
`

const Grid = styled('div')`
  ${tw`grid grid-flow-col grid-cols-2 auto-rows-auto gap-4 leading-normal`}

  margin-top: 50px;
`


const Company = styled('div')`
${tw`font-bold leading-normal tracking-widest`}
margin-bottom: 30px;

`

const SectionTitle = styled('div')`
${tw`font-bold whitespace-nowrap mb-4`}
`

const SectionDetails = styled('div')`
  ${tw`leading-tight`}

  margin-bottom: 20px;
  white-space: nowrap;

`

const CheckRoute = styled('a')`
${tw`mt-6 mb-10`}

  display: flex;
  justify-content: center;
  align-items: center;

  width: 230px;
  height: 80px;
  border: 1px solid rgb(35,35,35);

  margin-left:0;
`


const DepartmentList = styled('div')`
  ${tw`flex justify-start flex-col md:flex-row md:flex-wrap`}

`
const Department = styled('div')`
  ${tw`mr-32 mb-16`}

`

const departments =[
  {
    sectionTitle: 'Oddział Budzyń',
    address1: 'Budzyń 64-840',
    address2: 'ul. Rogozińska 52',
    routeUrl: 'https://www.google.com/maps/dir/?api=1&destination=/Rogozinska+52,+Budzyn',
    phone: '61 306 71 02'
  },
  {
    sectionTitle: 'Oddział Oborniki',
    address1: 'Oborniki 64-600',
    address2: 'ul. Obrzycka 88',
    routeUrl: 'https://www.google.com/maps/dir/?api=1&destination=/Oborniki+88,+Oborniki',
    phone: '61 306 71 02'
  },
  {
    sectionTitle: 'Oddział Ryczywół',
    address1: 'Ryczywół 64-630',
    address2: 'ul. Szkolna 1',
    routeUrl: 'https://www.google.com/maps/dir/?api=1&destination=/Szkolna+1,+Ryczywol',
    phone: '61 306 71 02'
  },
  {
    sectionTitle: 'Oddział Kłecko',
    address1: 'Kłecko 62-270',
    address2: 'ul. Gnieźnińska 7',
    routeUrl: 'https://www.google.com/maps/dir/?api=1&destination=/Gniezninska+7,+Kledzko',
    phone: '61 306 71 02'
  },
  {
    sectionTitle: 'Oddział Gąsawa',
    address1: 'Gąsawa 88-410',
    address2: 'ul. Leszka Białego 6',
    routeUrl: 'https://www.google.com/maps/dir/?api=1&destination=/Leszka+Bialego+6,+Gasawa',
    phone: '61 306 71 02'
  },
  {
    sectionTitle: 'Oddział Żnin',
    address1: 'Żnin 88-400',
    address2: 'ul. W. Pieniężnej 19',
    routeUrl: 'https://www.google.com/maps/dir/?api=1&destination=/Pienieznej+19,+Znin',
    phone: '+48 533 532 606'
  },
  {
    sectionTitle: 'Oddział Łabiszyn',
    address1: 'Łabiszyn 89-210',
    address2: 'ul. Powstańców Wielkopolskich 17A',
    routeUrl: 'https://www.google.com/maps/dir/?api=1&destination=/Pienieznej+19,+Znin',
    phone: '+48 533 532 606'
  },
  {
    sectionTitle: 'Oddział Krajenka',
    address1: 'Krajenka 77-430',
    address2: 'ul. Stanisława Polańskiego 3',
    routeUrl: 'https://www.google.com/maps/dir/?api=1&destination=/Stanislawa+Polanskiego+3,+Krajenka',
    phone: '61 306 71 02'
  },
  {
    sectionTitle: 'Oddział Rogoźno',
    address1: 'Rogoźno 64-610',
    address2: 'ul. Seminarialna 16',
    routeUrl: 'https://www.google.com/maps/dir/?api=1&destination=/Seminarialna+16,+Rogozno',
    phone: '61 306 71 02'
  },
  {
    sectionTitle: 'Oddział Wągrowiec Letnia',
    address1: 'Wągrowiec 62-100',
    address2: 'ul. Letnia 1',
    routeUrl: 'https://www.google.com/maps/dir/?api=1&destination=/Letnia+1,+Wagrowiec',
    phone: '61 306 71 02'
  },
]


const ContactTemplate:React.FC = ({data}) => {

  return (<Layout data={data}
    prop={{
      canonicalUrl: 'https://www.mediroot.pl/kontakt/',
      title: 'Stomatolog Wągrowiec - gabinet stomatologiczny - dentysta | Mediroot',
      description: 'Mediroot ➤ Stomatolog Wągrowiec - Stomatologia zachowawcza ✔ Stomatologia dziecięca ✔ Ortodoncja ✔ Protetyka stomatologiczna ✔ Zapoznaj się z ofertą i zadzwoń już dziś! ☎'
    }}>
    <HeroImg>

      <StaticImage
        src="../assets/images/hero.jpg"
        placeholder="blurred"
        alt="stomatolog Wągrowiec, gabinet stomatologiczny Wągrowiec, dentysta Wągrowiec"
        layout="fullWidth"/>
    </HeroImg>

    <MainContent>
      <ContactContent>

            <H1>Dane kontaktowe</H1>
            <DepartmentList>
              <Department>

                <SectionTitle>Siedziba główna</SectionTitle>
                <SectionDetails>
                  Wągrowiec<br/>
                  ul. św. Wojciecha 16
                </SectionDetails>

                <CheckRoute
                  href="https://www.google.com/maps/dir/?api=1&destination=/Wojciecha+16,+Wagrowiec"
                  rel="nofollow"
                  target="_blank">
                  Sprawdź dojazd
                </CheckRoute>


                <SectionTitle>Telefon:</SectionTitle>
                <SectionDetails>
                  <a href="tel:61 306 71 01">61 306 71 01</a>
                </SectionDetails>

                <SectionTitle>Godziny otwarcia:</SectionTitle>
                <SectionDetails>
                  pon-pt: 8:00&nbsp;-&nbsp;18:00<br/>
                  sob: 8:00&nbsp;-&nbsp;14:00
                </SectionDetails>
              </Department>

              {
                departments.map((t,i)=>{
                  return (
                  <Department key={i}>
                    <SectionTitle>{t.sectionTitle}</SectionTitle>
                    <SectionDetails>
                      {t.address1}<br/>
                      {t.address2}
                    </SectionDetails>
                    <CheckRoute
                      href={t.routeUrl}
                      rel="nofollow"
                      target="_blank">
                      Sprawdź dojazd
                    </CheckRoute>
                    <SectionTitle>Telefon:</SectionTitle>
                    <SectionDetails>
                      <a href={ 'tel:' + t.phone }>{t.phone}</a>
                    </SectionDetails>
                  </Department>
                  );
                })
              }
            </DepartmentList>


            <SectionTitle>E-mail:</SectionTitle>
            <SectionDetails>
              <a href = "mailto: mediroot.wagrowiec@gmail.com">mediroot.wagrowiec@gmail.com</a>
            </SectionDetails>
      </ContactContent>

      <SectionTitle>Dane do faktury</SectionTitle>
      <SectionDetails>
        Mediroot Urszula Korzeniowska<br/>
        ul. św. Wojciecha 16<br/>
        62-100 Wągrowiec<br/>
        NIP: 843-161-18-30
      </SectionDetails>
   </MainContent>

  </Layout>);
};

export default ContactTemplate;


export const query = graphql`
  query {
    logo: file(relativePath: { eq: "images/logo3.png" }) {
      childImageSharp {
        fluid( maxHeight: 92) {
          src
          sizes
          srcSet
          srcWebp
          base64
          aspectRatio
        }
      }
    }
  }
  `
